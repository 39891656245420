.rates-bank {
  &__content-col {
    @extend %col-tablet-8;
  }

  &__media-col {
    @extend %col-tablet-4;

    margin: 0;
    
    @media (min-width: $min-tablet) {
      text-align: right;
    }
  }

  &__content-col + &__media-col {
    @media (max-width: $max-mobile) {
      margin-top: 1em;
    }
  }
}
