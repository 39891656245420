.packages {
  &__col {
    @extend %col-tablet-4;

    margin-bottom: 16px;
  }

  &__col:nth-child(odd) {
    @media (min-width: $min-tablet) and (max-width: $max-tablet) {
      margin-top: 16px;
    }

    @media (min-width: $min-desktop) {
      margin-top: 46px;
    }
  }
}

.package {
  border-radius: 16px;
  border: solid 1px rgba(12, 7, 98, 0.1);
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__top {
    flex-grow: 1;
    padding: 25px 25px 30px;
  }

  &__footer {
    flex-shrink: 0;
    border-top: 1px solid #f0f0f0;
  }

  &__button-text {
    //
  }

  &__circle {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: solid 1px #d8d8d8;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      stroke: $c-dark-blue;
    }
  }

  &__button {
    appearance: none;
    padding: 17px 25px 20px;
    cursor: pointer;
    border: 0;
    background: transparent;
    display: flex;
    color: $c-blue;
    justify-content: space-between;

    &:hover {
      background: $c-blue;
      color: white;

      .package__circle svg {
        stroke: white;
      }
    }
  }

  &__figure {
    padding: 0;
    margin: 0 auto 20px;
    height: 105px;
    width: 120px;
    position: relative;
  }

  &__title {
    margin: 0 auto 4px;
  }

  &__price {
    margin: 0 auto 4px;
  }

  &__description {
    margin: 0;
    font-size: 14px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;

    &--hover {
      visibility: hidden;
    }
  }
}
