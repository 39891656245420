%clearfix::after {
  content: ' ';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

%child-margins {
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

%container {
  max-width: $grid-width;
  padding-left: $grid-padding;
  padding-right: $grid-padding;
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  @media (max-width: $max-mobile) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

%row {
  margin-left: -0.5 * $grid-padding;
  margin-right: -0.5 * $grid-padding;

  @media (max-width: $max-mobile) {
    margin-left: -8px;
    margin-right: -8px;
  }
}

%col {
  position: relative;
  min-height: 1px;
  padding-left: 0.5 * $grid-padding;
  padding-right: 0.5 * $grid-padding;
  float: left;

  @media (max-width: $max-mobile) {
    padding-left: 8px;
    padding-right: 8px;
  }
}

%col-mobile-12 {
  width: 100%;
}

%col-mobile-11 {
  width: 91.66666667%;
}

%col-mobile-10 {
  width: 83.33333333%;
}

%col-mobile-9 {
  width: 75%;
}

%col-mobile-8 {
  width: 66.66666667%;
}

%col-mobile-7 {
  width: 58.33333333%;
}

%col-mobile-6 {
  width: 50%;
}

%col-mobile-5 {
  width: 41.66666667%;
}

%col-mobile-4 {
  width: 33.33333333%;
}

%col-mobile-3 {
  width: 25%;
}

%col-mobile-2 {
  width: 16.66666667%;
}

%col-mobile-1 {
  width: 8.33333333%;
}

%col-mobile-pull-12 {
  right: 100%;
}

%col-mobile-pull-11 {
  right: 91.66666667%;
}

%col-mobile-pull-10 {
  right: 83.33333333%;
}

%col-mobile-pull-9 {
  right: 75%;
}

%col-mobile-pull-8 {
  right: 66.66666667%;
}

%col-mobile-pull-7 {
  right: 58.33333333%;
}

%col-mobile-pull-6 {
  right: 50%;
}

%col-mobile-pull-5 {
  right: 41.66666667%;
}

%col-mobile-pull-4 {
  right: 33.33333333%;
}

%col-mobile-pull-3 {
  right: 25%;
}

%col-mobile-pull-2 {
  right: 16.66666667%;
}

%col-mobile-pull-1 {
  right: 8.33333333%;
}

%col-mobile-pull-0 {
  right: auto;
}

%col-mobile-push-12 {
  left: 100%;
}

%col-mobile-push-11 {
  left: 91.66666667%;
}

%col-mobile-push-10 {
  left: 83.33333333%;
}

%col-mobile-push-9 {
  left: 75%;
}

%col-mobile-push-8 {
  left: 66.66666667%;
}

%col-mobile-push-7 {
  left: 58.33333333%;
}

%col-mobile-push-6 {
  left: 50%;
}

%col-mobile-push-5 {
  left: 41.66666667%;
}

%col-mobile-push-4 {
  left: 33.33333333%;
}

%col-mobile-push-3 {
  left: 25%;
}

%col-mobile-push-2 {
  left: 16.66666667%;
}

%col-mobile-push-1 {
  left: 8.33333333%;
}

%col-mobile-push-0 {
  left: auto;
}

%col-mobile-offset-12 {
  margin-left: 100%;
}

%col-mobile-offset-11 {
  margin-left: 91.66666667%;
}

%col-mobile-offset-10 {
  margin-left: 83.33333333%;
}

%col-mobile-offset-9 {
  margin-left: 75%;
}

%col-mobile-offset-8 {
  margin-left: 66.66666667%;
}

%col-mobile-offset-7 {
  margin-left: 58.33333333%;
}

%col-mobile-offset-6 {
  margin-left: 50%;
}

%col-mobile-offset-5 {
  margin-left: 41.66666667%;
}

%col-mobile-offset-4 {
  margin-left: 33.33333333%;
}

%col-mobile-offset-3 {
  margin-left: 25%;
}

%col-mobile-offset-2 {
  margin-left: 16.66666667%;
}

%col-mobile-offset-1 {
  margin-left: 8.33333333%;
}

%col-mobile-offset-0 {
  margin-left: 0%;
}

@media (min-width: $min-tablet) {
  %col-tablet-12 {
    width: 100%;
  }

  %col-tablet-11 {
    width: 91.66666667%;
  }

  %col-tablet-10 {
    width: 83.33333333%;
  }

  %col-tablet-9 {
    width: 75%;
  }

  %col-tablet-8 {
    width: 66.66666667%;
  }

  %col-tablet-7 {
    width: 58.33333333%;
  }

  %col-tablet-6 {
    width: 50%;
  }

  %col-tablet-5 {
    width: 41.66666667%;
  }

  %col-tablet-4 {
    width: 33.33333333%;
  }

  %col-tablet-3 {
    width: 25%;
  }

  %col-tablet-2 {
    width: 16.66666667%;
  }

  %col-tablet-1 {
    width: 8.33333333%;
  }

  %col-tablet-pull-12 {
    right: 100%;
  }

  %col-tablet-pull-11 {
    right: 91.66666667%;
  }

  %col-tablet-pull-10 {
    right: 83.33333333%;
  }

  %col-tablet-pull-9 {
    right: 75%;
  }

  %col-tablet-pull-8 {
    right: 66.66666667%;
  }

  %col-tablet-pull-7 {
    right: 58.33333333%;
  }

  %col-tablet-pull-6 {
    right: 50%;
  }

  %col-tablet-pull-5 {
    right: 41.66666667%;
  }

  %col-tablet-pull-4 {
    right: 33.33333333%;
  }

  %col-tablet-pull-3 {
    right: 25%;
  }

  %col-tablet-pull-2 {
    right: 16.66666667%;
  }

  %col-tablet-pull-1 {
    right: 8.33333333%;
  }

  %col-tablet-pull-0 {
    right: auto;
  }

  %col-tablet-push-12 {
    left: 100%;
  }

  %col-tablet-push-11 {
    left: 91.66666667%;
  }

  %col-tablet-push-10 {
    left: 83.33333333%;
  }

  %col-tablet-push-9 {
    left: 75%;
  }

  %col-tablet-push-8 {
    left: 66.66666667%;
  }

  %col-tablet-push-7 {
    left: 58.33333333%;
  }

  %col-tablet-push-6 {
    left: 50%;
  }

  %col-tablet-push-5 {
    left: 41.66666667%;
  }

  %col-tablet-push-4 {
    left: 33.33333333%;
  }

  %col-tablet-push-3 {
    left: 25%;
  }

  %col-tablet-push-2 {
    left: 16.66666667%;
  }

  %col-tablet-push-1 {
    left: 8.33333333%;
  }

  %col-tablet-push-0 {
    left: auto;
  }

  %col-tablet-offset-12 {
    margin-left: 100%;
  }

  %col-tablet-offset-11 {
    margin-left: 91.66666667%;
  }

  %col-tablet-offset-10 {
    margin-left: 83.33333333%;
  }

  %col-tablet-offset-9 {
    margin-left: 75%;
  }

  %col-tablet-offset-8 {
    margin-left: 66.66666667%;
  }

  %col-tablet-offset-7 {
    margin-left: 58.33333333%;
  }

  %col-tablet-offset-6 {
    margin-left: 50%;
  }

  %col-tablet-offset-5 {
    margin-left: 41.66666667%;
  }

  %col-tablet-offset-4 {
    margin-left: 33.33333333%;
  }

  %col-tablet-offset-3 {
    margin-left: 25%;
  }

  %col-tablet-offset-2 {
    margin-left: 16.66666667%;
  }

  %col-tablet-offset-1 {
    margin-left: 8.33333333%;
  }

  %col-tablet-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: $min-desktop) {
  %col-desktop-12 {
    width: 100%;
  }

  %col-desktop-11 {
    width: 91.66666667%;
  }

  %col-desktop-10 {
    width: 83.33333333%;
  }

  %col-desktop-9 {
    width: 75%;
  }

  %col-desktop-8 {
    width: 66.66666667%;
  }

  %col-desktop-7 {
    width: 58.33333333%;
  }

  %col-desktop-6 {
    width: 50%;
  }

  %col-desktop-5 {
    width: 41.66666667%;
  }

  %col-desktop-4 {
    width: 33.33333333%;
  }

  %col-desktop-3 {
    width: 25%;
  }

  %col-desktop-2 {
    width: 16.66666667%;
  }

  %col-desktop-1 {
    width: 8.33333333%;
  }

  %col-desktop-pull-12 {
    right: 100%;
  }

  %col-desktop-pull-11 {
    right: 91.66666667%;
  }

  %col-desktop-pull-10 {
    right: 83.33333333%;
  }

  %col-desktop-pull-9 {
    right: 75%;
  }

  %col-desktop-pull-8 {
    right: 66.66666667%;
  }

  %col-desktop-pull-7 {
    right: 58.33333333%;
  }

  %col-desktop-pull-6 {
    right: 50%;
  }

  %col-desktop-pull-5 {
    right: 41.66666667%;
  }

  %col-desktop-pull-4 {
    right: 33.33333333%;
  }

  %col-desktop-pull-3 {
    right: 25%;
  }

  %col-desktop-pull-2 {
    right: 16.66666667%;
  }

  %col-desktop-pull-1 {
    right: 8.33333333%;
  }

  %col-desktop-pull-0 {
    right: auto;
  }

  %col-desktop-push-12 {
    left: 100%;
  }

  %col-desktop-push-11 {
    left: 91.66666667%;
  }

  %col-desktop-push-10 {
    left: 83.33333333%;
  }

  %col-desktop-push-9 {
    left: 75%;
  }

  %col-desktop-push-8 {
    left: 66.66666667%;
  }

  %col-desktop-push-7 {
    left: 58.33333333%;
  }

  %col-desktop-push-6 {
    left: 50%;
  }

  %col-desktop-push-5 {
    left: 41.66666667%;
  }

  %col-desktop-push-4 {
    left: 33.33333333%;
  }

  %col-desktop-push-3 {
    left: 25%;
  }

  %col-desktop-push-2 {
    left: 16.66666667%;
  }

  %col-desktop-push-1 {
    left: 8.33333333%;
  }

  %col-desktop-push-0 {
    left: auto;
  }

  %col-desktop-offset-12 {
    margin-left: 100%;
  }

  %col-desktop-offset-11 {
    margin-left: 91.66666667%;
  }

  %col-desktop-offset-10 {
    margin-left: 83.33333333%;
  }

  %col-desktop-offset-9 {
    margin-left: 75%;
  }

  %col-desktop-offset-8 {
    margin-left: 66.66666667%;
  }

  %col-desktop-offset-7 {
    margin-left: 58.33333333%;
  }

  %col-desktop-offset-6 {
    margin-left: 50%;
  }

  %col-desktop-offset-5 {
    margin-left: 41.66666667%;
  }

  %col-desktop-offset-4 {
    margin-left: 33.33333333%;
  }

  %col-desktop-offset-3 {
    margin-left: 25%;
  }

  %col-desktop-offset-2 {
    margin-left: 16.66666667%;
  }

  %col-desktop-offset-1 {
    margin-left: 8.33333333%;
  }

  %col-desktop-offset-0 {
    margin-left: 0%;
  }
}

%clearfix:before,
%clearfix:after,
%container:before,
%container:after,
%container-fluid:before,
%container-fluid:after,
%row:before,
%row:after {
  content: ' ';
  display: table;
}

%clearfix:after,
%container:after,
%container-fluid:after,
%row:after {
  clear: both;
}
