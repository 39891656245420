.tooltip-button {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  color: white;
  line-height: 16px;
  text-align: center;
  background: $c-dark-blue;
  border: none;
  border-radius: 50%;
  font-size: 8px;
  padding: 0;
  vertical-align: text-top;
  cursor: pointer;

  &:hover {
    background-color: darken($c-dark-blue, 10%);
  }

  &__tooltip {
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translate(-50%, -14px);
    padding: 6px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    font-size: 12px;
    width: 140px;
    display: none;

    &::before {
      content: "";
      position: absolute;
      bottom: -8px;
      left: calc(50% - 8px);
      border-top: 8px solid rgba(0, 0, 0, 0.9);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }
  }
}
