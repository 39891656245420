.media-item {
  padding: 25px 32px;

  @media (max-width: $max-mobile) {
    padding: 25px 20px;
  }

  &__title {
    font-size: 24px !important;
    margin: 0 0 6px;

    @media (max-width: $max-mobile) {
      font-size: 18px !important;
    }
  }

  &__figure {
    width: 86px;
    height: 86px;
    float: left;
    flex-shrink: 0;

    @media (max-width: $max-mobile) {
      width: 55px;
      height: 55px;
    }
  }

  &__entry {
    padding-left: 132px;

    @media (max-width: $max-mobile) {
      padding-left: 80px;
    }
  }

  &__end {
    flex-shrink: 0;
  }

  &--flex {
    display: flex;
    align-items: center;
    gap: 34px;

    @media (max-width: $max-mobile) {
      gap: 25px;
      align-items: flex-start;
    }

    &__title {
      &+p {
        margin-top: 0;
      }
    }

    &__entry {
      padding: 0 !important;
      flex-grow: 1;
    }
  }

  &--advisor {
    padding: 38px 36px;

    @media (max-width: $max-mobile) {
      padding: 25px 20px;
    }

    &__figure {
      width: 108px;
      height: 108px;

      @media (max-width: $max-mobile) {
        width: 55px;
        height: 55px;
      }
    }

    &__entry {
      @media (min-width: $min-tablet) {
        padding-left: 132px;
      }

      a {
        text-decoration: none;
        color: $c-blue;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &--sidebar {
    padding: 32px 30px;

    &__figure {
      width: 60px;
      height: 60px;
    }

    &__entry {
      padding-left: 72px;
    }
  }

  &--sidebar-mini {
    padding: 32px 30px;

    &__figure {
      width: 39px;
      height: 39px;
    }

    &__entry {
      padding-left: 62px;
    }
  }

  &--sm {
    padding: 16px 18px;
    max-width: 480px;

    &__entry {
      @media (min-width: $min-tablet) {
        padding-left: 110px;
      }

      ul {
        padding-left: 1.2em;
      }
    }
  }
}