.location-panel {
  &__row {
    @media (min-width: $min-tablet) {
      display: flex;
    }
  }

  &__media-col {
    @extend %col-tablet-6;
  }

  &__figure {
    height: 100%;

    @media (max-width: $max-mobile) {
      height: 0;
      padding-bottom: 70%;
    }
  }

  &__content-col {
    @extend %col-tablet-6;
  }

  &__entry {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 20px;

    @media (max-width: $max-mobile) {
      padding: 30px 20px 20px;
    }
  }
}

.marker {
  align-items: center;
  width: 230px;
  display: inline-flex;

  &__marker {
    width: 32px;
    flex-shrink: 1;
  }

  &__location {
    flex-grow: 1;
    padding-left: 19px;
    width: 198px;
  }
}

.map {
  width: 100%;
  height: 420px;
  border: none;
  margin: 0;

  @media (max-width: $max-mobile) {
    height: 375px;
  }

  img {
    max-width: inherit !important;
  }

  &--lg {
    @media (min-width: $min-tablet) {
      height: 470px;
    }
  }
}
