html, body {
  background: $c-light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

body {
  font-family: $ff-regular;
  color: $c-dark-blue;
  font-size: $fs-regular;
  line-height: $lh-regular;
}

::-moz-selection {
  background: $c-dark-blue;
  color: white;
}

::selection {
  background: $c-dark-blue;
  color: white;
}

.p {
  font-family: $ff-regular;
  font-size: $fs-regular;
  line-height: $lh-regular;
  font-weight: 400;
}

.small {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: .1em;
  line-height: 1.57;
  text-transform: uppercase;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $ff-heading;
  font-weight: bold;
  line-height: 1.125;
  margin-top: 1em;
  margin-bottom: .5em;
  letter-spacing: 0;
}

h1,
.h1 {
  // font-size: 70px;
  font-size: 54px;
  line-height: 1.057;

  @media (max-width: $max-tablet) {
    font-size: 28px;
  }
}

h2,
.h2 {
  font-size: 48px;

  @media (max-width: $max-tablet) {
    font-size: 24px;
  }
}

h3,
.h3 {
  font-size: 32px;

  @media (max-width: $max-tablet) {
    font-size: 24px;
  }
}

h4,
.h4 {
  font-size: 20px;

  @media (max-width: $max-tablet) {
    font-size: 16px;
  }
}

.intro {
  font-size: 24px;

  @media (max-width: $max-tablet) {
    font-size: 18px;
  }
}

a {
  &,
  &:hover,
  &:visited,
  &:active,
  &:focus {
    color: inherit;
    outline: none;
    text-decoration: none;
  }
}

@include placeholder {
  color: rgba($c-dark-blue, 0.4);
}

small {
  font-size: 12px;
}

.content {
  @extend %child-margins;

  a {
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  h2 {
    font-size: 32px;

    @media (max-width: $max-tablet) {
      font-size: 24px;
    }
  }

  h3 {
    font-size: 20px;

    @media (max-width: $max-tablet) {
      font-size: 16px;
    }
  }
}
