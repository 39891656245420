.button {
  border-radius: 100px;
  cursor: pointer;
  display: inline-block;
  background-color: $c-blue;
  font-size: $fs-regular;
  font-weight: 400;
  font-family: $ff-regular;
  padding: 15px 24px;
  line-height: 1;
  min-width: 40px;
  text-align: center;
  text-decoration: none !important;
  border: 1px solid $c-blue;

  &:not(.button--static):hover {
    background-color: darken($c-blue, 10%);
  }

  &,
  &:not(.button--static):hover,
  &:visited {
    color: white;
  }

  &--sm {
    padding: 8px 10px;
  }

  &--dark-blue {
    background-color: $c-dark-blue;
    border: 1px solid $c-dark-blue;

    &:not(.button--static):hover {
      background-color: darken($c-dark-blue, 10%);
    }
  }

  &--white {
    background-color: white;
    border: 1px solid white;

    &,
    &:not(.button--static):hover,
    &:visited {
      color: $c-dark-blue;
    }

    &:not(.button--static):hover {
      background-color: $c-yellow;
      border-color: $c-yellow;
    }
  }

  &--gray {
    background-color: rgba($c-dark-blue, .1);
    color: $c-dark-blue;
    border: 1px solid rgba($c-dark-blue, .1);

    &:visited {
      color: $c-dark-blue;
    }

    &:not(.button--static):hover,
    &.active {
      color: white;

      &:visited {
        color: white;
      }
    }

    &:not(.button--static):hover,
    &.active {
      background-color: lighten($c-blue, .5);
    }
  }

  &--yellow {
    background-color: $c-yellow;
    border-color: $c-yellow;

    &,
    &:not(.button--static):hover,
    &:visited {
      color: $c-blue;
    }

    &:not(.button--static):hover {
      background-color: darken($c-yellow, 10%);
    }
  }

  &--ghost {
    background-color: transparent;
    border: 1px solid $c-dark-blue;

    &,
    &:not(.button--static):hover,
    &:visited {
      color: $c-dark-blue;
    }

    &:not(.button--static):hover {
      background-color: darken($c-light, 10%);
    }
  }

  &--ghost-gray {
    background-color: transparent;
    border: 1px solid #D8D8D8;

    &,
    &:not(.button--static):hover,
    &:visited {
      color: $c-dark-blue;
    }

    &:not(.button--static):hover {
      background-color: darken($c-light, 10%);
    }
  }

  &--ghost-blue {
    background-color: transparent;
    border: 1px solid $c-blue;

    &,
    &:visited {
      color: $c-blue;
    }

    &:not(.button--static):hover {
      background-color: $c-blue;
      color: white;

      &:visited {
        color: white;
      }
    }
  }

  &--ghost-light {
    background-color: transparent;
    border: 1px solid white;

    .button--icon__image {
      fill: white;
    }

    &,
    &:visited {
      color: white;
    }

    &:not(.button--static):hover {
      background-color: white;
      color: $c-blue;

      .button--icon__image {
        fill: $c-blue;
      }

      &:visited {
        color: $c-blue;
      }
    }
  }

  &--icon {
    font-size: 0;

    &__image,
    &__span {
      display: inline-block;
      vertical-align: middle;
    }

    &__image {
      position: relative;
      top: 1px;
    }

    &__span {
      margin-left: 9px;
      font-size: $fs-regular;
    }
  }

  &--block {
    width: 100%;
    display: block;
  }

  &--block-mobile {
    @media (max-width: $max-mobile) {
      width: 100%;
      display: block;
    }
  }

  &--arrow {
    background-image: url(~/assets/images/white-arrow.svg);
    background-size: 16.737px 14.116px;
    background-position: center right 20px;
    background-repeat: no-repeat;
    padding-right: 50px;
  }

  &--arrow-alt {
    &::after {
      content: '';
      background-image: url(~/assets/images/white-arrow.svg);
      background-size: 16.737px 14.116px;
      background-repeat: no-repeat;
      transform: rotate(-45deg);
      width: 16.373px;
      height: 14.116px;
      z-index: 1;
      display: inline-block;
      margin-left: 5px;
    }
  }

  &--static {
    cursor: initial;
  }
}

.language-button {
  background: white;
  border-radius: 100px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2857;
  padding: 4px 10px 2px;
  margin-right: 32px;

  @media (max-width: 1260px) {
    margin-right: 13px;
  }

  @media (max-width: 1150px) {
    margin-right: 8px;
  }

  @media (max-width: 380px) {
    margin-right: 2px;
    margin-left: 5px;
  }

  &:hover {
    background: $c-yellow;
  }

  &,
  &:active,
  &:hover,
  &:visited {
    color: $c-blue;
  }
}

.arrow {
  padding-right: 30px;
  background-image: url(~/assets/images/blue-arrow.svg);
  background-size: 16.737px 14.116px;
  background-position: top 6px right;
  background-repeat: no-repeat;
  display: inline-block;

  &,
  &:focus,
  &:visited {
    color: $c-blue;
  }

  &:hover {
    color: $c-dark-blue;
  }
}

.arrow-inline {
  &::after {
    content: '';
    display: inline-block;
    background-image: url(~/assets/images/blue-arrow.svg);
    width: 15.24px;
    height: 12px;
    background-size: 15.24px 12px;
    margin-left: 8px;
    background-repeat: no-repeat;
  }
}

.buttons-list {
  list-style: none;
  padding: 0;

  &__item {
    display: inline-block;
    margin: .5em;
    vertical-align: middle;

    @media (max-width: $max-mobile) {
      margin: 0 0.5em 0.5em 0;
    }
  }

  &--left {
    .buttons-list__item:first-child {
      margin-left: 0;
    }
  }
}