/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * muli:
 *   - http://typekit.com/eulas/00000000000000007735a0ee
 *   - http://typekit.com/eulas/00000000000000007735a103
 * nimbus-sans-extended:
 *   - http://typekit.com/eulas/000000000000000077359e1d
 *   - http://typekit.com/eulas/000000000000000077359e22
 *
 * © 2009-2021 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2021-11-08 08:48:41 UTC"}*/

@import url('https://p.typekit.net/p.css?s=1&k=vcj0ulv&ht=tk&f=10804.10805.15782.37497&a=3120980&app=typekit&e=css');

// @font-face {
//   font-family: 'nimbus-sans-extended';
//   src:
//     url('https://use.typekit.net/af/222328/000000000000000077359e1d/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3') format('woff2'),
//     url('https://use.typekit.net/af/222328/000000000000000077359e1d/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3') format('woff');
//   font-display: swap;
//   font-style: normal;
//   font-weight: 400;
// }

@font-face {
  font-family: 'nimbus-sans-extended';
  src:
    url('https://use.typekit.net/af/d9b817/000000000000000077359e22/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3') format('woff2'),
    url('https://use.typekit.net/af/d9b817/000000000000000077359e22/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3') format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'muli';
  src:
    url('https://use.typekit.net/af/7fe570/00000000000000007735a0ee/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff2'),
    url('https://use.typekit.net/af/7fe570/00000000000000007735a0ee/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3') format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'muli';
  src:
    url('https://use.typekit.net/af/b0bf15/00000000000000007735a103/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff2'),
    url('https://use.typekit.net/af/b0bf15/00000000000000007735a103/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3') format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

// Colors
$c-light: #F0F0F0;
$c-blue: #1051ff;
$c-yellow: #ffebb2;
$c-dark-blue: #110066;
$c-gray: #D8D8D8;

// Font-families
$ff-regular: 'muli', 'Arial', sans-serif;
$ff-heading: 'nimbus-sans-extended', 'Arial', sans-serif;

// Font-sizes
$fs-regular: 16px;

// Line-heights
$lh-regular: 1.625;

// Grid
$grid-width: 1288px;
$grid-padding: 40px;
$spacer: 77px;

// Media-queries
$min-desktop: 1024px;
$max-tablet: 1023px;
$min-tablet: 767px;
$max-mobile: 766px;

:root {
  --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  --ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  --ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  --ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  --ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
  --ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
  --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
  --ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
  --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
  --ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
  --ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
  --ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
