.sidebar-block {
  overflow: hidden;

  &,
  &__wrap {
    padding: 32px 30px;
  }

  &--no-padding {
    padding: 0;
  }

  &__figure {
    height: 0;
    padding-bottom: 50%;
  }

  &__title {
    margin-top: 5px;
    margin-bottom: 9px;
  }

  &__description {
    margin-top: 0;
    margin-bottom: 9px;

    ul {
      padding-left: 20px;
    }
  }

  &__phone {
    margin: 0 auto 18px;
    display: flex;
    align-items: center;
    gap: 9px;
  }
}

.detail-form {
  &__header {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__icon {
    flex-shrink: 0;
  }

  &__title {
    flex-grow: 1;
  }

  &__form {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  &__footer {
    // margin-top: 14px;
  }
}