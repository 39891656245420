.footer {
  font-size: 14px;

  &__col {
    @extend %col-tablet-3;

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__col + &__col {
    margin-top: 1em;
  }

  &__logo {
    margin-bottom: 23px;
    max-width: 100%;
    height: auto;
  }

  &__top {
    padding-top: 50px;
    padding-bottom: 75px;

    @media (max-width: $max-mobile) {
      padding-bottom: 50px;
    }
  }
  
  &__bottom {
    border-top: 1px solid $c-blue;
    padding-top: 31px;
    padding-bottom: 31px;

    @media (max-width: $max-mobile) {
      padding-top: 26px;
      padding-bottom: 26px;
    }
  }

  &__title {
    font-weight: 700;
    margin: 0 0 1em;
  }

  &__menu {
    margin: 0;
    list-style-type: none;
    padding: 0;

    li {
      margin: 0;
    }
  }
}