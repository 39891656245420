$nav-height: 88px;

.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $nav-height;
  z-index: 1000;
  background: $c-blue;
  color: white;

  &__container {
    display: flex;
    align-items: center;

    @media (min-width: $min-desktop) {
      height: 100%;
    }
  }

  &__middle {
    flex-grow: 1;
    margin-left: 39px;

    @media (max-width: $max-tablet) {
      margin-left: 0;
    }

    .button {
      padding: 9px 16px;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    margin-left: 15px;

    @media (max-width: 340px) {
      margin-left: 8px;
    }

    .button {
      padding: 10px 15px;
    }
  }
}

.navbar-fix {
  height: $nav-height;
}

.nav-media {
  margin-right: 35px;
  font-size: 0;

  @media (max-width: 1200px) {
    margin-right: 15px;
  }

  @media (max-width: 340px) {
    margin-right: 8px;
  }

  &__logo {
    &--mobile {
      @media (max-width: 365px) {
        display: none;
      }
    }

    &--xs {
      @media (min-width: 366px) {
        display: none;
      }
    }
  }
}

.nav-menu {
  padding: 0;
  list-style: none;
  margin: 0;
  flex-grow: 1;
  letter-spacing: 0.03em;

  a {
    display: block;
  }

  > .menu-item {
    display: inline-block;
    line-height: 1;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 16px;
      right: 16px;
      bottom: 26px;
      transform: translateY(3px);
      opacity: 0;
      transition: all 300ms var(--ease-in-out-expo);
      transition-property: opacity, transform;
      will-change: opacity, transform;
      height: 1px;
      background: white;

      @media (max-width: 1200px) {
        left: 10px;
        right: 10px;
      }
  
      @media (max-width: 1100px) {
        left: 5px;
        right: 5px;
      }
    }

    > a {
      padding-left: 16px;
      padding-right: 16px;

      @media (max-width: 1200px) {
        padding-left: 10px;
        padding-right: 10px;
      }

      @media (max-width: 1100px) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    &:hover {
      &::after {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &.menu-item-has-children {
      > a {
        padding-top: 36px;
        padding-bottom: 36px;
      }
    }
  }
}

.menu-initialised .h-submenu {
  transition: transform 500ms var(--ease-in-out-expo) 500ms;
}

.submenu-hover .menu-item-has-children:hover {
  .h-submenu {
    transition: transform 500ms var(--ease-out-expo);
    transform: translateY(0);
    z-index: -1;

    .container {
      opacity: 1;
      transition: opacity 600ms var(--ease-in-out-expo);
    }
  }
}

.submenu-in .h-submenu {
  transition: transform 500ms var(--ease-out-expo) 200ms !important;
}

.submenu-out .h-submenu {
  transition: transform 500ms var(--ease-in-out-expo) !important;
}

.h-submenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: -2;
  background: $c-dark-blue;
  max-height: 100%;
  overflow-y: auto;
  line-height: $lh-regular;
  transform: translateY(-100%);
  // transition: transform 500ms var(--ease-in-out-expo) 500ms;
  will-change: transform;

  .container {
    opacity: 0;
    transition: opacity 100ms var(--ease-in-out-expo);
    will-change: opacity;
  }

  .row {
    width: 75%;
    padding-top: 158px;
    padding-bottom: 70px;

    @media (max-width: $max-tablet) {
      width: 100%;;
    }
  }

  &__content-col {
    @extend %col-tablet-6;
    // @extend %col-desktop-4;

    h2,
    .h2 {
      color: $c-yellow;
      font-size: 36px;
      opacity: 1;
    }

    p {
      opacity: .7;
    }
  }

  &__content-col + &__menu-col {
    @media (max-width: $max-mobile) {
      margin-top: 2em;
    }
  }

  &__menu-col {
    // @extend %col-tablet-6;
    // @extend %col-desktop-5;
    @extend %col-tablet-6;
  }
}

.arrow-menu {
  list-style: none;
  padding: 0;
  margin: 0;

  @media (max-width: $max-tablet) {
    margin-top: 50px;
  }

  .menu-item {
    font-size: 20px;
    line-height: 1.5;
    
    a {
      display: block;

      &:hover {
        opacity: .7;
      }
    }
  }

  @media (min-width: $min-desktop) {
    a {
      padding-right: 25px;
      background-image: url(~/assets/images/white-arrow.svg);
      background-size: 16.737px 14.116px;
      background-position: top 7px right;
      background-repeat: no-repeat;
    }
  }

  .menu-item-has-children {
    a {
      padding-right: 25px;
      background-image: url(~/assets/images/white-arrow.svg);
      background-size: 16.737px 14.116px;
      background-position: top 7px right;
      background-repeat: no-repeat;
    }
  }

  @media (max-width: $max-tablet) {
    position: relative;

    .menu-item.is-active {
      .mobile-submenu {
        transform: translateX(0);
        pointer-events: initial;
        transition: transform 300ms var(--ease-out-expo);
      }
    }
  }

  .menu-item + .menu-item {
    margin-top: 1.5em;
  }
}

.mobile-submenu {
  position: fixed;
  top: $nav-height;
  left: 0;
  right: 0;
  transform: translateX(100%);
  pointer-events: none;
  transition: transform 300ms var(--ease-in-out-expo);
  padding: 0 16px 40px;
  background: $c-dark-blue;
  z-index: 1000;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;

  &__back {
    padding: 20px 0 20px 20px;
    cursor: pointer;
    border: none;
    transform: rotate(180deg);
    background: none;
    cursor: pointer;

    img {
      pointer-events: none;
    }
  }

  &__content {
    font-size: 16px;

    h2,
    .h2 {
      color: $c-yellow;
      font-size: 36px;
      opacity: 1;
    }

    p {
      opacity: .7;
    }
  }
}

.nav-buttons {
  padding: 0;
  margin: 0;
  list-style: none;

  .menu-item {
    display: inline-block
  }

  .menu-item + .menu-item {
    margin-left: 15px;

    @media (max-width: 1260px) {
      margin-left: 6px;
    }

    @media (max-width: 1100px) {
      margin-left: 3px;
    }
  }

  a {
    @extend .button;
    @extend .button--ghost-light;

    @media (max-width: 1260px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media (max-width: 1100px) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

.nav-hamburger {
  -webkit-appearance: none;
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 22px;
  height: $nav-height;
  background-color: transparent;
  float: right;
  font-size: 0;
  text-indent: -9999px;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background 0.2s;
  opacity: 1;
  margin-left: 10px;

  @media (max-width: 380px) {
    margin-left: 3px;
  }

  span {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    background-color: white;
    top: 50%;
    height: 1.5px;
    transition: background 0s 0.2s;
    transform: translateY(-50%);

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      background-color: white;
      height: 1.5px;
      transition-duration: 0.2s, 0.2s;
      transition-delay: 0.2s, 0s;
    }

    &::before {
      top: -9px;
      transition-property: top, transform;
    }

    &::after {
      bottom: -9px;
      transition-property: bottom, transform;
    }
  }

  &--is-active {
    background-color: transparent;

    span {
      background: none;

      &::before,
      &::after {
        transition-delay: 0s, 0.2s;
        background: white;
      }

      &::before {
        top: 0;
        transform: rotate(45deg);
      }

      &::after {
        bottom: 0;
        transform: rotate(-45deg);
      }
    }
  }

  &:focus {
    outline: none;
  }
}

.nav-slide {
  transform: translateX(100%);
  pointer-events: none;
  transition: transform 300ms var(--ease-out-expo);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1010;
  padding-bottom: 40px;

  .nav-media {
    flex-grow: 1;
  }
}

.body--slide-active {
  overflow: hidden;

  .nav-slide {
    transform: translateX(0);
    pointer-events: initial;
  }
}