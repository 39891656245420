.cookie-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1020;
  padding-top: 28px;
  padding-bottom: 28px;
  transition: 500ms transform var(--ease-out-expo);
  transform: translateY(-100%);
  will-change: transform;

  @media (min-width: $min-tablet) {
    &__row {
      display: flex;
      align-items: center;
    }
  }

  &__container {
    position: relative;
  }

  &__content-col {
    @extend %col-desktop-10;
    @extend %col-tablet-9;
  }

  &__button-col {
    @extend %col-desktop-2;
    @extend %col-tablet-3;

    text-align: right;
  }

  &__content-col + &__button-col {
    @media (max-width: $max-mobile) {
      margin-top: 1em;
      text-align: left;
    }
  }

  &__close {
    position: absolute;
    top: -25px;
    right: 40px;
    background: none;
    padding: 10px 0 10px 10px;
    cursor: pointer;
    border: none;

    @media (max-width: $max-mobile) {
      right: 16px;
    }
  }
}

.cookie-banner-active {
  .cookie-banner {
    transform: translateY(0);
  }

  .nav,
  .wrapper {
    transition: 500ms transform var(--ease-out-expo);
  }
}