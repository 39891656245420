.quote {
  &__col {
    &--left {
      @extend %col-tablet-6;
      @extend %col-desktop-6;
    }

    &--right {
      @extend %col-tablet-6;
      @extend %col-desktop-offset-1;
      @extend %col-desktop-5;
    }
  }

  &__col + &__col {
    @media (max-width: $max-mobile) {
      margin-top: 2em;
    }
  }
}