.side-to-side {
  &__left-col {
    @extend %col-tablet-5;
  }

  &__right-col {
    @extend %col-tablet-7;
  }

  &__left-col + &__right-col {
    @media (max-width: $max-mobile) {
      margin-top: 2em;
    }
  }

  &__title {
    margin-bottom: 10px;
    font-size: 32px;

    @media (max-width: $max-mobile) {
      font-size: 24px;
    }
  }
}

.content-tabs {
  &__item {
    padding: 16px 32px;
    border-radius: 16px;
    border: solid 1px rgba(12, 7, 98, 0.1);
    background-color: white;
    font-size: 18px;
    line-height: 1.44;
    font-weight: bold;
    font-family: $ff-heading;
    color: $c-dark-blue;
    cursor: pointer;
    text-align: left;
    display: block;
    width: calc(100% - 60px);
    position: relative;

    @media (max-width: $max-tablet) {
      width: 100%;
    }

    @media (min-width: $min-tablet) {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -10px;
        display: none;
        width: 0; 
        height: 0; 
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid $c-blue;
      }

      &[data-tab-state="selected"]::after {
        display: block;
      }
    }

    &:hover,
    &[data-tab-state="selected"] {
      background-color: $c-blue;
      color: white;
    }
  }

  &__item + &__item {
    margin-top: 16px;
  }
}
