@media (max-width: $max-mobile) {
  .glide {
    position: relative;
    width: 100%;
    box-sizing: border-box;

    * {
      box-sizing: inherit;
    }

    &__track {
      overflow: hidden;
    }

    &__slides {
      position: relative;
      width: 100%;
      list-style: none;
      backface-visibility: hidden;
      transform-style: preserve-3d;
      touch-action: pan-Y;
      overflow: hidden;
      margin: 0;
      padding: 0;
      white-space: nowrap;
      display: flex;
      flex-wrap: nowrap;
      will-change: transform;

      &--dragging {
        user-select: none;
      }
    }

    &__slide {
      // width: 100%;
      height: 100%;
      flex-shrink: 0;
      white-space: normal;
      user-select: none;
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: transparent;

      a {
        user-select: none;
        -webkit-user-drag: none;
        -moz-user-select: none;
        -ms-user-select: none;
      }
    }

    &__arrows {
      -webkit-touch-callout: none;
      user-select: none;
    }

    &__bullets {
      -webkit-touch-callout: none;
      user-select: none;
    }

    &--rtl {
      direction: rtl;
    }
  }
}