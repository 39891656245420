.mortgage-expl {
  &__content {
    margin-bottom: 15px;
  }
}

.lib {
  &__nav {
    text-align: center;
    border-bottom: 1px solid #D8D8D8;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    max-width: calc(100% - 285px);
    display: inline-block;
    font-size: 0;
    vertical-align: middle;

    @media (max-width: $max-mobile) {
      max-width: 100%;
      display: block;
      position: relative;
    }
  }

  &__item {
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    font-size: $fs-regular;

    @media (min-width: $min-tablet) {
      position: relative;
    }

    > a {
      width: 32px;
      height: 32px;
      line-height: 32px;
      display: inline-block;
    }

    &--active,
    &:hover {
      background: $c-blue;
      color: white;
      border-radius: 50%;
    }

    &:hover,
    &:focus,
    &:focus-within {
      .lib__inner-wrap {
        transition-duration: 600ms;
        transition-timing-function: var(--ease-out-expo);
        transform: translateY(0);
        opacity: 1;
        pointer-events: initial;
      }
    }
  }

  &__inner-wrap {
    position: absolute;
    width: 296px;
    top: 100%;
    left: calc(50% - 148px);
    opacity: 0;
    pointer-events: none;
    z-index: 1;
    padding-top: 20px;
    transition: all 400ms var(--ease-in-out-expo);
    transition-property: transform, opacity;
    will-change: transform, opacity;
    transform: translateY(5px);

    @media (max-width: 500px) {
      width: 100%;
      left: 0;
    }

    &::before {
      content: '';
      width: 24px;
      height: 24px;
      background: white;
      transform: rotate(45deg);
      left: calc(50% - 12px);
      top: 10px;
      position: absolute;
      z-index: 2;
    }
  }

  &__submenu {
    padding: 25px 25px 18px;
    border: 1px solid rgba($c-dark-blue, .1);
    text-transform: initial;
    text-align: left;
    margin: 0;
    list-style: none;
    overflow-y: auto;

    @media (min-width: $min-tablet) {
      max-height: 400px;
    }
  }

  &__sub-item {
    display: block;
    padding: 3px 0;

    &--active {
      font-weight: bold;
    }
  }
}

.search-form {
  position: relative;
  display: inline-block;
  width: 250px;
  vertical-align: middle;

  @media (max-width: $max-mobile) {
    margin-top: 15px;
    width: 100%;
    display: block;
  }

  @media (min-width: $min-tablet) {
    margin-left: 30px;
  }

  &__icon {
    position: absolute;
    top: 14px;
    left: 13px;
  }

  &__input {
    padding: 14px 13px 14px 46px;
    border: none;
    font-size: 16px;
    width: 100%;
  }
}