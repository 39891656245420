.news {
  &__col {
    margin-bottom: 30px;

    @media (min-width: $min-tablet) {
      margin-bottom: 40px;

      &--lg {
        width: 60%;
      }

      &--sm {
        width: 40%;
      }

      &--sm-alt {
        width: 33.33%;
      }
    }
  }
}

.news-box {
  &__small {
    margin-bottom: 10px;

    & + * {
      margin-top: 0;
    }
  }

  &--block {
    @media (min-width: $min-tablet) {
      position: relative;
      align-items: center;
      display: flex;

      &::after {
        content: '';
        height: 0;
        padding-bottom: 96.92%;
        display: block;
      }
    }

    &__wrapper {
      padding: 42px 24px;
      width: 100%;

      @media (min-width: $min-tablet) {
        position: absolute;
        padding: 30px;
      }
    }

    &__header,
    &__entry {
      margin-bottom: 30px;
    }
  }

  &--card {
    @media (min-width: $min-tablet) {
      height: 0;
      padding-bottom: 150.53%;
    }

    &__figure {
      width: 100%;
      height: 0;
      padding-bottom: 95.5%;
    }

    &__wrapper {
      padding-bottom: 54.5%;
      height: 0;
      overflow: hidden;
    }

    &__entry {
      padding: 30px;
    }

    &__title {
      @media (max-width: $max-mobile) {
        font-size: 20px;
      }
    }

    &:hover {
      background: $c-blue;
      color: $c-yellow;
    }
  }

  &--image {
    height: 0;
    padding-bottom: 92.02%;
    position: relative;

    &__figure {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &__title {
      position: absolute;
      padding: 20px;
      bottom: 0;
      left: 0;
      width: 100%;
      color: white;

      @media (min-width: $min-tablet) and (max-width: $max-tablet) {
        font-size: 20px;
      }
    }

    &:hover {
      opacity: .8;
    }
  }

  &--card-side {
    background: #E3E1F2;

    @media (min-width: $min-tablet) {
      height: 0;
      padding-bottom: 59.25%;
    }

    &__figure {
      width: 35%;
      height: 0;
      float: left;
      padding-bottom: 59.25%;

      @media (max-width: $max-mobile) {
        display: none;
      }
    }

    &__entry {
      @media (min-width: $min-tablet) {
        height: 0;
        width: 65%;
        padding-bottom: 59.25%;
        float: left;
      }
    }

    &__wrapper {
      padding: 50px 30px 50px 40px;

      @media (max-width: $max-tablet) {
        padding: 30px;
      }
    }

    &:hover {
      background: $c-blue;
      color: $c-yellow;
    }
  }
}

.what-can-we-do {
  &__col {
    &--lg {
      @extend %col-tablet-6;
    }

    &--sm {
      @extend %col-desktop-offset-1;
      @extend %col-desktop-5;
      @extend %col-tablet-6;

      @media (max-width: $max-mobile) {
        margin-top: 2em;
      }
    }
  }

  &__panel {
    padding: 42px;

    @media (max-width: $max-mobile) {
      padding: 30px 20px;
    }
  }
  
  &__figure {
    width: 86px;
    height: 86px;
    margin: 0 auto;
  }
}
