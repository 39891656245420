.related-articles {
  .glide {
    @media (max-width: $max-mobile) {
      width: calc(100% + 32px);
      margin-left: -16px;
      margin-right: -16px;
    }
  }

  .glide__track {
    @media (max-width: $max-mobile) {
      padding-left: 16px;
    }
  }
}