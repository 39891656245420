.home-hero {
  padding-top: 44px;
  padding-bottom: 60px;

  @media (max-width: $max-mobile) {
    padding-top: 5px;
    padding-bottom: 25px;
  }

  &--rounded {
    border-radius: 16px;
    padding: 50px 100px;

    @media (max-width: $max-mobile) {
      padding: 20px 10px 10px;
    }
  }

  &__h1 {
    margin: 0 0 7px;
  }

  &__header {
    margin-bottom: 48px;

    @media (max-width: $max-mobile) {
      margin-bottom: 30px;
    }
  }

  &__cta {
    margin-left: 35px;
    margin-bottom: 9px;
    font-size: 19px;
  }
}

.form-block {
  &__title {
    margin-bottom: 24px;
  }
}

.lowest-rate {
  &__content-col {
    @extend %col-desktop-6;
    @extend %col-tablet-12;

    @media (min-width: $min-desktop) {
      padding-right: 50px;
    }
  }

  &__media-col {
    @extend %col-desktop-6;
    @extend %col-tablet-12;

    font-size: 0;

    @media (max-width: $max-tablet) {
      margin-top: 24px;
    }
  }

  &__image {
    box-shadow: 0 8px 20px rgba($c-dark-blue, .1);
  }

  &__trustpilot {
    padding: 22px;
    margin: -32px auto 0;
    display: inline-block;
    position: relative;
    z-index: 1;
  }

  .glide {
    @media (max-width: $max-mobile) {
      width: calc(100% + 32px);
      margin-left: -16px;
      margin-right: -16px;
    }
  }

  .glide__track {
    @media (max-width: $max-mobile) {
      padding-left: 16px;
    }
  }
}

.topics {
  &__col {
    @extend %col-tablet-6;
    @extend %col-desktop-3;

    margin-bottom: 16px;
  }

  &__col:nth-child(even) {
    @media (min-width: $min-tablet) and (max-width: $max-tablet) {
      margin-top: 16px;
    }

    @media (min-width: $min-desktop) {
      margin-top: 46px;
    }
  }
}

.topic-card {
  width: 100%;
  position: relative;

  @media (min-width: $min-desktop) {
    height: 0;
    padding-bottom: 130%;
  }

  @media (max-width: $max-tablet) {
    text-align: left;
    padding: 12px 20px;
    display: flex;
    align-items: center;
  }

  &:hover {
    .topic-card__image {
      &--regular {
        visibility: hidden;
      }
      
      &--hover {
        visibility: visible;
      }
    }
  }

  &__figure {
    padding: 0;
    margin: 0;

    @media (min-width: $min-desktop) {
      position: absolute;
      top: 30px;
      height: 65%;
      left: 0;
      right: 0;
    }

    @media (max-width: $max-tablet) {
      flex-shrink: 1;
      width: 65px;
      position: relative;
      height: 65px;
    }
  }

  &__image {
    position: absolute;

    @media (min-width: $min-desktop) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media (max-width: $max-tablet) {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &--hover {
      visibility: hidden;
    }
  }

  &__title {
    margin: 0;

    @media (min-width: $min-desktop) {
      position: absolute;
      top: 78%;
      left: 20px;
      right: 20px;
    }

    @media (max-width: $max-tablet) {
      padding-left: 25px;
      padding-right: 20px;
      position: relative;
      flex-grow: 1;
      background-image: url(~/assets/images/arrow.svg);
      background-size: 17px 14px;
      background-position: right center;
      background-repeat: no-repeat;
    }
  }

  @media (min-width: $min-desktop) {
    &:hover {
      .topic-card__title {
        &::after {
          content: '';
          display: inline-block;
          background-image: url(~/assets/images/white-arrow.svg);
          background-size: 17px 14px;
          background-position: center;
          background-repeat: no-repeat;
          width: 17px;
          height: 14px;
          margin-left: 10px;
          vertical-align: middle;
        }
      }
    }
  }
}

.full-service {
  &__col {
    @extend %col-tablet-4;
    @extend %col-mobile-8;
  }

  &__content {
    @media (max-width: $max-mobile) {
      text-align: left;
    }
  }

  .glide {
    @media (max-width: $max-mobile) {
      width: calc(100% + 32px);
      margin-left: -16px;
      margin-right: -16px;
    }
  }

  .glide__track {
    @media (max-width: $max-mobile) {
      padding-left: 16px;
    }
  }
}

.fs-article {
  &__figure {
    width: 100%;
    padding-bottom: 96%;
  }

  &__entry {
    margin: 24px 0 0;
  }

  &__title {
    font-size: 20px;
    margin: 0 0 7px;
  }

  &__excerpt {
    margin: 0;
  }

  &__new {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10;
    font-weight: bold;
    padding: 10px 22px;
    color: $c-dark-blue;
  }

  &__large {
    font-size: 24px;
    font-weight: bold;
    font-family: $ff-heading;
    margin: 0;
  }

  &--acc {
    &__figure {
      padding-bottom: 80%;
    }

    &__button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
      z-index: 10;
    }

    &:hover {
      .fs-article--acc__button {
        display: block;
      }
    }
  }

  @at-root a > .fs-article:hover {
    opacity: .8;
  }
}

.locations {
  @media (max-width: $max-mobile) {
    text-align: left;
  }
}