.taglines {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 6px 48px;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  justify-content: center;
  color: $c-dark-blue;
  line-height: 1;

  @media (max-width: $max-mobile) {
    margin-top: 1em;
    gap: 6px 20px;
  }

  &__item {
    position: relative;
    text-align: center;
    display: inline-block;
    padding-left: 30px;

    &::before {
      content: '';
      background-image: url(~/assets/images/checkbox.svg);
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center;
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: middle;
      left: -10px;
      position: relative;
      margin-left: -20px;
    }
  }

  &__button {
    display: inline-block;
    padding: 4px 8px 4px 8px;
    border: 1px solid $c-dark-blue;;
    border-radius: 13px;

    &:hover {
      background: $c-dark-blue;
      color: white;
    }
  }

  &--white {
    color: rgba(white, 0.8);

    &__item::before {
      background-image: url(~/assets/images/checkbox-white.svg);
    }

    &__button {
      border: 1px solid rgba(white, 0.6);

      &:hover {
        background: white;
        color: $c-blue;
      }
    }
  }
}