.tools {
  &__col {
    @extend %col-tablet-6;
  }

  &__col + &__col {
    @media (max-width: $max-mobile) {
      margin-top: 2em;
    }
  }

  .glide {
    @media (max-width: $max-mobile) {
      margin-left: -16px;
      margin-right: -16px;
      width: calc(100% + 32px);
    }
  }

  .glide__track {
    @media (max-width: $max-mobile) {
      padding-left: 16px;
    }
  }
}
