.progress-bar {
  position: fixed;
  top: 88px;
  height: 8px;
  left: 0;
  right: 0;
  background: #D8D8D8;
  overflow: hidden;
  z-index: 980;

  &__progress {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
    background: #FFC105;
  }
}