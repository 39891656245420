.trustpilot {
  &__row {
    @media (min-width: $min-tablet) {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    font-family: $ff-heading;
    letter-spacing: 0;

    @media (max-width: $max-mobile) {
      font-size: 18px;
    }
  }

  &__col {
    @extend %col-tablet-6;

    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;

    @media (max-width: $max-mobile) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &--blue {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

.trustpilot-sm {
  padding: 22px;
  max-width: 480px;
}
