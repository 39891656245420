.inline-article-list {
  padding: 0;
  margin: 0;
  list-style-type: none;

  &__item {
    margin: 0;
  }

  &__item + &__item {
    margin-top: 16px;
  }

  &__button {
    appearance: none;
    padding: 0;
    border-radius: 0;
    cursor: pointer;
    background: none;
    display: block;
    border: none;
    width: 100%;
    text-align: left;
  }
}

.inline-article {
  display: flex;
  // padding: 24px 35px;
  padding: 19px 35px;
  align-items: center;
  border: 1px solid rgba($c-dark-blue, 0.1);
  position: relative;

  @media (max-width: $max-mobile) {
    padding: 12px 20px;
  }

  &:hover {
    background: $c-blue;
    color: white;

    .inline-article__stroke {
      stroke: white;
    }

    .inline-article__image {
      &--regular {
        visibility: hidden;
      }
      
      &--hover {
        visibility: visible;
      }
    }
  }

  &__figure {
    width: 80px;
    height: 80px;
    position: relative;
    margin: 0;
    padding: 0;
    flex-shrink: 0;

    @media (max-width: $max-mobile) {
      width: 65px;
      height: 65px;
    }
  }

  &__arrow {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: $max-mobile) {
      right: 20px;
    }
  }

  &__stroke {
    stroke: $c-dark-blue;
  }

  &__image {
    max-width: 80px;
    max-height: 80px;
    position: absolute;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: $max-mobile) {
      max-width: 65px;
      max-height: 65px;
    }

    &--hover {
      visibility: hidden;
    }
  }

  &__entry {
    flex-grow: 1;
    padding-right: 30px;
  }

  &__title {
    flex-grow: 1;
    margin: 0 0 0 30px;
  }
}