.branches {
  &__col {
    @extend %col-desktop-12;
    @extend %col-tablet-6;

    margin-bottom: 24px;

    @media (max-width: $max-mobile) {
      margin-bottom: 16px;
    }

    @media (min-width: $min-tablet) and (max-width: $max-tablet) {
      &:nth-child(2n+3) {
        clear: both;
      }
    }
  }
}

.branch {
  background: white;

  @media (min-width: $min-desktop) {
    display: flex;
  }

  &__figure {
    width: 272px;

    @media (max-width: $max-tablet) {
      width: 100%;
      height: 200px;  
    }
  }

  &__main {
    flex: 4;
  }

  &__buttons {
    @media (min-width: $min-desktop) {
      display: flex;
      flex: 5;
      justify-content: space-between;
    }

    @media (max-width: $max-tablet) {
      margin-top: 1em;
    }

    .button {
      @media (max-width: $max-tablet) {
        width: 100%;
      }
    }

    .button + .button {
      @media (min-width: $min-desktop) {
        margin-left: 15px;
      }

      @media (max-width: $max-tablet) {
        margin-top: 15px;
      }
    }
  }

  &__entry {
    @media (min-width: $min-desktop) {
      display: flex;
      align-items: center;
      flex-grow: 1;
      padding: 40px;
    }

    @media (max-width: $max-tablet) {
      padding: 30px;
    }
  }

  &__title {
    font-size: 24px;
    margin: 0 0 6px;
  }
}
