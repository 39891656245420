.breadcrumb {
  list-style: none;
  padding: 0;
  margin: 0;
  color: $c-blue;

  &__item {
    display: inline-block;
    margin: 0;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__item + &__item {
    &::before {
      content: ' • ';
    }
  }
}

.bg-blue {
  .breadcrumb {
    color: white;
  }
}