.amount-item {
  padding: 35px;
  margin-bottom: 16px;

  &__media {
    width: 80px;
    height: 80px;
    float: left;
  }

  &__entry {
    padding-left: 125px;
  }

  &__amount {
    margin: 0;
  }
}