.floating-cta {
  position: fixed;
  z-index: 990;
  opacity: 0;
  transition: all 500ms var(--ease-out-expo);
  transform: translateY(100%);
  transition-property: opacity, transform;
  will-change: opacity, transform;
  display: none;

  @media (max-width: $max-mobile) {
    display: block;
    width: 84px;
    right: 0;
    bottom: 0;
    padding: 10px;
    border-radius: 0;
    overflow: initial;
  }

  &--active {
    transform: translateY(0);
    opacity: 1;
  }

  &__whatsapp-link {
    font-size: 0;
    display: inline-block;
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(black, 0.16);
  }
}