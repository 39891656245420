.houses {
  &__col {
    @extend %col-tablet-4;

    margin-bottom: 20px;
  }
}

.pagination {
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 8px;
    justify-content: center;
  }

  &__link {
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
    color: $c-dark-blue;
    display: inline-block;

    &:hover,
    &[aria-current="true"] {
      background: $c-blue;
      color: white;
    }
  }
}

.search-results-header {
  display: flex;
  flex-wrap: wrap;

  &__title {
    flex-grow: 1;
  }

  &__sort {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.sort-group {
  &__label {
    //
  }

  &__label,
  &__select {
    display: inline-block;
    vertical-align: top;
  }

  &__select {
    font-family: $ff-regular;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    padding: 12px 45px 12px 24px;
    margin: 0;
    border: 1px solid #D8D8D8;
    border-radius: 100px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    background-image: url(../images/select-chevron.svg);
    background-repeat: no-repeat;
    background-position: right 17px top 21px;
    background-size: 12px 6px;
    color: $c-dark-blue;
    cursor: pointer;
    width: auto;
  }
}