*,
*::before,
*::after {
  box-sizing: border-box;
}

.clearfix {
  @extend %clearfix;
}

.container {
  @extend %container;

  &--10 {
    max-width: 1080px;
  }

  &--8 {
    max-width: 870px;
  }

  &--6 {
    max-width: 664px;
  }
}

.child-margins {
  @extend %child-margins;
}

.row {
  @extend %row;

  width: calc(100% + 40px);

  @media (max-width: $max-mobile) {
    width: calc(100% + 16px);
  }
}

.col {
  @extend %col;

  max-width: 100%;
}

.col--mf {
  @media (max-width: $max-mobile) {
    width: 100%;
  }
}

.flex-desktop {
  @media (min-width: $min-tablet) {
    display: flex;
    align-items: center;
  }

  @media (max-width: $max-mobile) {
    &::before {
      content: ' ';
      display: table;
    }

    &::after {
      content: ' ';
      visibility: hidden;
      display: table;
      height: 0;
      clear: both;
    }
  }
}

.hidden-mobile {
  @media (max-width: $max-mobile) {
    display: none !important;
  }
}

.hidden-tablet {
  @media (min-width: $min-tablet) and (max-width: $max-tablet) {
    display: none !important;
  }
}

.hidden-desktop {
  @media (min-width: $min-desktop) {
    display: none !important;
  }
}

.bg-light {
  background: $c-light;
}

.l.bg-light + .l.bg-light {
  border-top: 1px solid #D8D8D8;
}

.bg-blue {
  background: $c-blue;
  color: $c-yellow;
}

.color-blue {
  color: $c-blue;
}

.color-dark-blue {
  color: $c-dark-blue;
}

a.color-blue {
  &:visited {
    color: $c-blue;
  }

  &:hover {
    color: $c-dark-blue;
  }
}

.bg-dark-blue {
  background: $c-dark-blue;
  color: white;
}

.bg-yellow {
  background: $c-yellow;
  color: $c-dark-blue;
}

.bg-white {
  background: white;
  color: $c-dark-blue;
}

.bg-faded {
  background: rgba($c-dark-blue, 0.3);
  color: white;
}

.bg-gray {
  background: $c-gray;
  color: $c-dark-blue;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.wrapper {
  overflow: hidden;
}

.sr-only {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}

.rounded {
  border-radius: 16px;
  overflow: hidden;

  &--sm {
    border-radius: 8px;
  }
}

.circle {
  border-radius: 50%;
  overflow: hidden;
}

.box-shadow {
  box-shadow: 0 8px 20px rgba($c-dark-blue, 0.1);
}

.box {
  background: white;
  border-radius: 16px;
  box-shadow: 0 8px 50px 0 rgba(0, 0, 0, 0.2);
  color: $c-dark-blue;

  &--sm {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  }

  &--hover {
    &:hover {
      background: $c-blue;
      color: white;
    }
  }
}

.of {
  padding: 0;
  margin: 0;
  position: relative;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--contain {
    &__image {
      object-fit: contain;
    }
  }
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: $spacer * .25 !important;

  @media (max-width: $max-mobile) {
    padding-bottom: $spacer * .2 !important;
  }

  // @media (max-width: $max-mobile) and (max-height: 740px) {
  //   padding-bottom: $spacer * .15 !important;
  // }
}

.pb-2 {
  padding-bottom: $spacer * .5 !important;

  @media (max-width: $max-mobile) {
    padding-bottom: $spacer * .4 !important;
  }

  @media (max-width: $max-mobile) and (max-height: 740px) {
    padding-bottom: $spacer * .3 !important;
  }
}

.pb-3 {
  padding-bottom: $spacer !important;

  @media (max-width: $max-mobile) {
    padding-bottom: $spacer * .8 !important;
  }

  @media (max-width: $max-mobile) and (max-height: 740px) {
    padding-bottom: $spacer * .45 !important;
  }
}

.pb-4 {
  padding-bottom: $spacer * 1.25 !important;

  @media (max-width: $max-mobile) {
    padding-bottom: $spacer !important;
  }

  @media (max-width: $max-mobile) and (max-height: 740px) {
    padding-bottom: $spacer * .6 !important;
  }
}

.pb-5 {
  padding-bottom: $spacer * 1.5 !important;

  @media (max-width: $max-mobile) {
    padding-bottom: $spacer * 1.2 !important;
  }

  @media (max-width: $max-mobile) and (max-height: 740px) {
    padding-bottom: $spacer * .75 !important;
  }
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: $spacer * .25 !important;

  @media (max-width: $max-mobile) {
    padding-top: $spacer * .2 !important;
  }

  // @media (max-width: $max-mobile) and (max-height: 740px) {
  //   padding-top: $spacer * .15 !important;
  // }
}

.pt-2 {
  padding-top: $spacer * .5 !important;

  @media (max-width: $max-mobile) {
    padding-top: $spacer * .4 !important;
  }

  @media (max-width: $max-mobile) and (max-height: 740px) {
    padding-top: $spacer * .3 !important;
  }
}

.pt-3 {
  padding-top: $spacer !important;

  @media (max-width: $max-mobile) {
    padding-top: $spacer * .8 !important;
  }

  @media (max-width: $max-mobile) and (max-height: 740px) {
    padding-top: $spacer * .45 !important;
  }
}

.pt-4 {
  padding-top: $spacer * 1.25 !important;

  @media (max-width: $max-mobile) {
    padding-top: $spacer !important;
  }

  @media (max-width: $max-mobile) and (max-height: 740px) {
    padding-top: $spacer * .6 !important;
  }
}

.pt-5 {
  padding-top: $spacer * 1.5 !important;

  @media (max-width: $max-mobile) {
    padding-top: $spacer * 1.2 !important;
  }

  @media (max-width: $max-mobile) and (max-height: 740px) {
    padding-top: $spacer * .75 !important;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: $spacer * .25 !important;

  @media (max-width: $max-mobile) {
    margin-bottom: $spacer * .2 !important;
  }

  // @media (max-width: $max-mobile) and (max-height: 740px) {
  //   margin-bottom: $spacer * .15 !important;
  // }
}

.mb-2 {
  margin-bottom: $spacer * .5 !important;

  @media (max-width: $max-mobile) {
    margin-bottom: $spacer * .4 !important;
  }

  @media (max-width: $max-mobile) and (max-height: 740px) {
    margin-bottom: $spacer * .3 !important;
  }
}

.mb-3 {
  margin-bottom: $spacer !important;

  @media (max-width: $max-mobile) {
    margin-bottom: $spacer * .8 !important;
  }

  @media (max-width: $max-mobile) and (max-height: 740px) {
    margin-bottom: $spacer * .45 !important;
  }
}

.mb-4 {
  margin-bottom: $spacer * 1.25 !important;

  @media (max-width: $max-mobile) {
    margin-bottom: $spacer !important;
  }

  @media (max-width: $max-mobile) and (max-height: 740px) {
    margin-bottom: $spacer * .6 !important;
  }
}

.mb-5 {
  margin-bottom: $spacer * 1.5 !important;

  @media (max-width: $max-mobile) {
    margin-bottom: $spacer * 1.2 !important;
  }

  @media (max-width: $max-mobile) and (max-height: 740px) {
    margin-bottom: $spacer * .75 !important;
  }
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: $spacer * .25 !important;

  @media (max-width: $max-mobile) {
    margin-top: $spacer * .2 !important;
  }

  // @media (max-width: $max-mobile) and (max-height: 740px) {
  //   margin-top: $spacer * .15 !important;
  // }
}

.mt-2 {
  margin-top: $spacer * .5 !important;

  @media (max-width: $max-mobile) {
    margin-top: $spacer * .4 !important;
  }

  @media (max-width: $max-mobile) and (max-height: 740px) {
    margin-top: $spacer * .3 !important;
  }
}

.mt-3 {
  margin-top: $spacer !important;

  @media (max-width: $max-mobile) {
    margin-top: $spacer * .8 !important;
  }

  @media (max-width: $max-mobile) and (max-height: 740px) {
    margin-top: $spacer * .45 !important;
  }
}

.mt-4 {
  margin-top: $spacer * 1.25 !important;

  @media (max-width: $max-mobile) {
    margin-top: $spacer !important;
  }

  @media (max-width: $max-mobile) and (max-height: 740px) {
    margin-top: $spacer * .6 !important;
  }
}

.mt-5 {
  margin-top: $spacer * 1.5 !important;

  @media (max-width: $max-mobile) {
    margin-top: $spacer * 1.2 !important;
  }

  @media (max-width: $max-mobile) and (max-height: 740px) {
    margin-top: $spacer * .75 !important;
  }
}