.ls-layout {
  &__col-large {
    @extend %col-desktop-8;
    @extend %col-tablet-7;
  }

  &__col-small {
    @extend %col-desktop-4;
    @extend %col-tablet-5;

    display: flex;
    gap: 24px;
    flex-direction: column;
  }

  &__col-large + &__col-small {
    @media (max-width: $max-mobile) {
      margin-top: 1em;
    }
  }
}

.woning-detail {
  &__figure {
    height: 0;
    padding-bottom: 37%;

    @media (max-width: $max-mobile) {
      padding-bottom: 50%;
    }
  }

  &__figure + &__container {
    margin-top: -137px;

    @media (max-width: $max-mobile) {
      margin-top: -30px;
    }
  }

  &__top {
    padding: 45px 40px 30px 40px;
    border-bottom: 1px solid #f0f0f0;

    @media (max-width: $max-mobile) {
      padding: 20px;
    }
  }

  &__content-wrap {
    padding: 40px;

    @media (max-width: $max-mobile) {
      padding: 20px;
    }

    &.is-active {
      .woning-detail__content {
        max-height: initial;
      }

      .woning-detail__button-wrap::before {
        display: none;
      }
    }
  }

  &__content {
    max-height: 280px;
    overflow: hidden;
  }

  &__info {
    @media (min-width: $min-tablet) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__header {
    flex-grow: 1;
  }

  &__title {
    margin-bottom: 5px;
  }

  &__address {
    margin: 0;
  }

  &__button {
    flex-shrink: 0;

    @media (max-width: $max-mobile) {
      margin-top: 14px;
    }
  }

  &__content-wrap {
    //
  }

  &__button-wrap {
    position: relative;
    padding-top: 10px;

    &::before {
      content: '';
      height: 70px;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
      left: 0;
      right: 0;
      position: absolute;
      bottom: 100%;
    }
  }
}

.woning-features {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  gap: 12px 64px;
  flex-wrap: wrap;

  &__item {
    display: flex;
    gap: 16px;
    align-items: center;
  }
}

.photoreel {
  position: relative;
  z-index: 1030;
  display: none;
  pointer-events: none;

  &.is-shown {
    pointer-events: initial;

    .photoreel__backdrop {
      transition: opacity 200ms var(--ease-out-quad);
      opacity: 1;
    }

    .photoreel__wrap {
      transition: all 200ms var(--ease-in-quad);
      transform: scale(1);
      opacity: 1;
    }
  }

  &__backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($c-dark-blue, .9);
    opacity: 0;
    transition: opacity 300ms var(--ease-in-quad);
    will-change: opacity;
  }

  &__wrap {
    overflow-y: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 200ms var(--ease-in-quad);
    transition-property: transform, opacity;
    will-change: transform, opacity;
    opacity: 0;
    transform: scale(0.95);
  }

  &__slides {
    overflow: hidden;
    width: 100%;

    &.is-draggable {
      cursor: move;
      cursor: grab;
    }

    &.is-dragging {
      cursor: grabbing;
    }
  }

  &__container {
    max-width: 100%;
    padding-top: 80px;
    padding-bottom: 20px;
  }

  &__slides-container {
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  &__slide {
    position: relative;
    min-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__media {
    padding: 0;
    margin: 0;
    height: calc(100vh - 160px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__figure {
    position: relative;
    margin: 0 auto;
    padding: 0;
  }

  &__image {
    max-width: 100%;
    height: auto;
    max-height: 100%;
    width: auto;
    border-radius: 16px;
    overflow: hidden;
  }

  &__navigation {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }

  &__button {
    border: 1px solid white;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    line-height: 32px;
    position: relative;
    background: transparent;
    cursor: pointer;

    &:hover {
      background: rgba(white, 0.1);
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--prev {
      svg {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    &--next {
      svg {
        transform: translate(-50%, -50%) rotate(-90deg);
      }
    }
  }

  &__ml {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__close {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 20;
  }

  &__num {
    color: white;
  }
}