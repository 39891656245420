.advisors {
  &__col {
    @extend %col-tablet-6;

    margin-bottom: 20px;

    @media (min-width: $min-tablet) {
      &:nth-child(2n+3) {
        clear: both;
      }
    }
  }
}
